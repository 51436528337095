import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PBox } from '../components/CenterBox';
import Layout from './Layout';
import Home from './Home';
import Dashboard from './Dashboard';
import Download from './Download';
import { env } from '../api';

export function App() {
  React.useEffect(() => {
    document.title = env.title;
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/account'} element={<Layout />}>
          <Route index element={<Navigate to={'dashboard'} replace />} />
          <Route path={'dashboard'} element={<Dashboard />} />
          <Route path={'pool/:poolname'} element={<Dashboard />} />
          <Route path={'*'} element={<PBox />} />
        </Route>
        <Route path={'/'} element={<Layout requireSignIn={false} />}>
          <Route index element={<Navigate to={'home'} replace />} />
          <Route path={'home'} element={<Home />} />
          <Route path={'download'} element={<Download />} />
          <Route path={'*'} element={<PBox />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
