import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Stack, Chip, TextField, InputAdornment, Typography, capitalize } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import CircularProgress from '@mui/material/CircularProgress';
import { SimpleTaskDialog, delay } from '../abelcommon';
import { useLayoutState } from '../components/BarDrawerLayout';
import { env, useUserState, getPoolAPI } from '../api';
import { useGoogleRecaptchaV3, getRecaptchaToken } from './grechaptcha';

type AuthMultipoolDialogName = 'signin' | 'signout';
const ActiveDialogContext = React.createContext<AuthMultipoolDialogName>(null);

interface AuthMultipoolDialogProps {
  setActiveDialog: (name: AuthMultipoolDialogName) => void;
  username?: string;
}

type SignInStatus = null | 'waiting' | 'failed' | 'error' | object;

function SignInStatusSign({ status }: { status: SignInStatus }) {
  if (String(status) === 'waiting') {
    return <CircularProgress size={'1rem'} />;
  }

  const text =
    status === null ? '-' : typeof status == 'object' ? '🟢' : status === 'failed' ? 'failed 🟡' : 'error 🔴';
  return <Typography>{text}</Typography>;
}

function SignInMultipoolDialog({ setActiveDialog }: AuthMultipoolDialogProps) {
  const [layoutState, setLayoutState] = useLayoutState();
  const activeDialog = React.useContext(ActiveDialogContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userStates = Object.fromEntries(env.poolnames.map((pn: string) => [pn, useUserState(pn)]));
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const signinStates = Object.fromEntries(env.poolnames.map((pn: string) => [pn, React.useState<SignInStatus>(null)]));

  const closeMobileDrawer = () => {
    if (!layoutState.isDesktop) {
      setLayoutState({ ...layoutState, mobileDrawerOpen: false });
    }
  };

  return (
    <SimpleTaskDialog
      initOpen={activeDialog === 'signin'}
      onClose={() => {
        env.poolnames.map((pn: string): void => {
          const signinState = signinStates[pn][0];
          if (signinState !== null && typeof signinState === 'object') {
            const setUserState = userStates[pn][1];
            setUserState(signinState);
            setActiveDialog(null);
            setTimeout(closeMobileDrawer, 600);
            navigate('/account/dashboard');
          }
          return null;
        });
        setActiveDialog(null);
      }}
      fullScreen={false}
      sx={{ '& .MuiPaper-root': { borderRadius: 2, minWidth: 360 } }}
      title={`Sign in to ${env.title}`}
      content={(step: string) => (
        <Stack spacing={2} sx={{ width: 300 }}>
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-username'}
            label={'Username'}
            value={username}
            onChange={(e) => setUsername(e.target.value.replace(/\s/g, ''))}
            disabled={step !== 'PREPARE'}
            fullWidth
          />
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-password'}
            label={'Password'}
            type={'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={step !== 'PREPARE'}
            fullWidth
          />
          <Stack>
            <Typography>Authentication Status:</Typography>
            {env.poolnames.map((pn: string) => {
              const [userState] = userStates[pn];
              return (
                <Stack key={pn} direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ width: '120px' }}>{`Pool - ${capitalize(userState.poolname)}`}</Typography>
                  <SignInStatusSign status={signinStates[pn][0]} />
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
      submitButtonProps={{ children: 'Sign In' }}
      cancelButtonProps={{ sx: { display: 'none' } }}
      doneButtonProps={{ children: 'Continue' }}
      workingText={'Signing in...'}
      successText={'Successfully signed in.'}
      prepare={() => {
        if (!username) {
          return 'Please input username.';
        } else if (username.includes(' ')) {
          return 'Invalid username.';
        } else if (!password) {
          return 'Please input password.';
        } else {
          return '';
        }
      }}
      work={async () => {
        const rechaptchaToken = await getRecaptchaToken();
        const signinResults = await Promise.all(
          env.poolnames.map(async (pn: string) => {
            const setSignInState = signinStates[pn][1];
            setSignInState('waiting');
            await delay(1000);
            try {
              const res = await getPoolAPI(pn).signin(username, password, rechaptchaToken);
              if (res.status !== 200 || res.data.type !== 'signin') {
                console.error('Unexpected response: ' + JSON.stringify(res.data));
                setSignInState('failed');
                return false;
              } else {
                setSignInState({ poolname: pn, username: username, signinAt: Date.now(), token: res.data.payload.jwt });
                return true;
              }
            } catch (err: any) {
              console.error(err);
              setSignInState(err.code === 'ERR_NETWORK' ? 'error' : 'failed');
              return false;
            }
          })
        );
        if (!signinResults.includes(true)) {
          return 'Failed signing in any pool.';
        }
        return '';
      }}
    />
  );
}

function SignOutMultipoolDialog({ setActiveDialog, username }: AuthMultipoolDialogProps) {
  const [layoutState, setLayoutState] = useLayoutState();
  const activeDialog = React.useContext(ActiveDialogContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userStates = Object.fromEntries(env.poolnames.map((pn: string) => [pn, useUserState(pn)]));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let signedOut = false;

  const closeMobileDrawer = () => {
    if (!layoutState.isDesktop) {
      setLayoutState({ ...layoutState, mobileDrawerOpen: false });
    }
  };

  return (
    <SimpleTaskDialog
      initOpen={activeDialog === 'signout'}
      onClose={() => {
        if (signedOut) {
          env.poolnames.map((pn: string) => userStates[pn][1]({ poolname: pn }));
          queryClient.resetQueries();
          queryClient.clear();
          setActiveDialog(null);
          setTimeout(closeMobileDrawer, 600);
          navigate('/home');
        }
        setActiveDialog(null);
      }}
      fullScreen={false}
      sx={{ '& .MuiPaper-root': { borderRadius: 2, minWidth: 360 } }}
      title={`Sign out of ${env.title}`}
      content={(step: string) => (
        <Stack spacing={2}>
          <Typography color={'text.secondary'}>Your account name is {username}.</Typography>
          <Typography color={'text.secondary'}>Are you sure to sign out all pools?</Typography>
        </Stack>
      )}
      submitButtonProps={{ children: 'Sign Out', variant: 'outlined' }}
      cancelButtonProps={{ children: 'No, I will stay signed in.', variant: 'contained' }}
      doneButtonProps={{ children: 'Continue' }}
      workingText={'Signing out...'}
      successText={'Successfully signed out.'}
      work={async () => {
        await delay(1000);
        signedOut = true;
        return '';
      }}
    />
  );
}

export function SignInOutMultipool() {
  const [layoutState] = useLayoutState();
  const [activeDialog, setActiveDialog] = React.useState<AuthMultipoolDialogName>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userStates = Object.fromEntries(env.poolnames.map((pn: string) => [pn, useUserState(pn)]));

  useGoogleRecaptchaV3();

  const signedinPoolnames = env.poolnames.filter((pn: string) => userStates[pn][0].token);
  const signedin = signedinPoolnames.length > 0;
  const username = signedin ? userStates[signedinPoolnames[0]][0].username : '';
  const buttonText = signedin ? username : 'Sign In';

  return (
    <ActiveDialogContext.Provider value={activeDialog}>
      <Chip
        label={buttonText}
        onClick={() => setActiveDialog(signedin ? 'signout' : 'signin')}
        sx={{
          maxWidth: layoutState.isDesktop ? 220 : 190,
          mr: layoutState.isDesktop ? 1.5 : 0.5,
          fontSize: buttonText > 20 ? '0.7em' : '0.8em',
        }}
      />
      {signedin && <SignOutMultipoolDialog setActiveDialog={setActiveDialog} username={username} />}
      {!signedin && <SignInMultipoolDialog setActiveDialog={setActiveDialog} />}
    </ActiveDialogContext.Provider>
  );
}

export default SignInOutMultipool;
