import { AtomOptions, RecoilState, atom } from 'recoil';

function localStorageEffect(key: string) {
  return ({ setSelf, onSet }: { setSelf: any; onSet: any }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });

    const storageListener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setSelf(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', storageListener);
  };
}

export function persistentAtom<T>(
  options: AtomOptions<T>,
  persistentKey?: string
): RecoilState<T> {
  return atom<T>({
    ...options,
    effects: [localStorageEffect(persistentKey ?? options.key)],
  });
}
