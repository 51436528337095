import React from 'react';
import { environment as env } from '../environments/environment';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

function useScript(url: string) {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
}

export function useGoogleRecaptchaV3() {
  if (env.recaptchaV3.enabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScript(`https://www.google.com/recaptcha/api.js?render=${env.recaptchaV3.siteKey}`);
  }
}

export async function getRecaptchaToken(action: string = 'submit') {
  if (!env.recaptchaV3.enabled) {
    return '';
  }
  let token = '';
  await window.grecaptcha.execute(env.recaptchaV3.siteKey, { action: action }).then((res: string) => {
    token = res;
  });
  return token;
}
