import React from 'react';
import { UseQueryResult } from 'react-query';
import { Stack, Tooltip, Typography } from '@mui/material';
import { env, getPoolAPI, useSignedinPoolnames } from '../../api';

export default function GlobalOverview() {
  const signedinPoolnames = useSignedinPoolnames();
  const queries = env.poolnames.map((pn: string) => getPoolAPI(pn).useUserProfile());

  const totalBalance = queries.reduce((r: any, q: UseQueryResult<any, unknown>) => {
    if (!q.isSuccess) {
      return r;
    }
    const d = q.data.payload;
    return r + Number(d.balance / 1e7);
  }, 0);

  return (
    <Stack spacing={2} alignItems={'center'}>
      <Typography variant={'h4'} color={'appBlue.lightest'}>
        You have mined
      </Typography>
      <Tooltip title={totalBalance.toLocaleString('en-US')} placement={'right'}>
        <Typography variant={'h2'} color={'orange'}>
          {totalBalance.toLocaleString('en-US', { maximumFractionDigits: 2 })}
        </Typography>
      </Tooltip>
      <Typography variant={'h4'} color={'appBlue.light'}>
        ABEL
      </Typography>
      <Typography variant={'h4'} color={'appBlue.lightest'}>
        in {signedinPoolnames.length} mining pool{signedinPoolnames.length > 1 ? 's' : ''}.
      </Typography>
    </Stack>
  );
}
