import React from 'react';
import { Typography, Box } from '@mui/material';
import { KeyedObject, SmartLink } from '../abelcommon';

export function Emphasis({ children }: { children: React.ReactNode }) {
  return (
    <Typography component={'span'} sx={{ fontWeight: 'bold', color: 'appBlue.lightest' }}>
      {children}
    </Typography>
  );
}

export function StyledSmartLink(props: KeyedObject) {
  const { children, ...otherProps } = props;
  return (
    <SmartLink {...otherProps} sx={{ color: 'appBlue.lightest' }}>
      {children}
    </SmartLink>
  );
}

export function CodeBox(props: KeyedObject) {
  const { children } = props;
  return (
    <Box sx={{ fontFamily: 'Monospace', my: 1, p: 1, border: 1, borderRadius: 1, borderColor: 'appGray.light' }}>
      {children}
    </Box>
  );
}
