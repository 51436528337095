import React from 'react';
import { Stack, Typography, Button, TextField, InputAdornment, SxProps } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CenterBox from '../../components/CenterBox';
import { getPoolAPI, useUsername, useUserState } from '../../api';

export default function PoolSignIn({
  poolname,
  showUsername = false,
  sx,
}: {
  poolname: string;
  showUsername?: boolean;
  sx?: SxProps;
}) {
  const username = useUsername();
  const [password, setPassword] = React.useState('');
  const [, setUserState] = useUserState(poolname);
  const api = getPoolAPI(poolname);
  const [isWorking, setIsWorking] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  if (!username) {
    return null;
  }

  const handleSubmit = async () => {
    if (isWorking) {
      return;
    }
    setIsFailed(false);
    setIsWorking(true);
    try {
      const res = await api.signin(username, password, '');
      if (res.status !== 200 || res.data.type !== 'signin') {
        console.error('Unexpected response: ' + JSON.stringify(res.data));
        setIsFailed(true);
        return;
      }
      setUserState({ poolname: poolname, username: username, signinAt: Date.now(), token: res.data.payload.jwt });
    } catch (err: any) {
      console.error(err);
      setIsFailed(true);
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <Stack spacing={2} alignItems={'center'} sx={sx}>
      {showUsername && (
        <TextField
          variant={'standard'}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          margin={'dense'}
          id={'input-username'}
          label={'Username'}
          value={username}
          disabled
          fullWidth
          sx={{ maxWidth: 300 }}
        />
      )}
      <TextField
        variant={'standard'}
        InputProps={{
          endAdornment: (
            <InputAdornment position={'end'}>
              <KeyIcon />
            </InputAdornment>
          ),
        }}
        margin={'dense'}
        id={`input-${poolname}-password`}
        label={'Password'}
        type={'password'}
        value={password}
        onChange={(e) => {
          if (isFailed) {
            setIsFailed(false);
          }
          setPassword(e.target.value);
        }}
        disabled={isWorking}
        fullWidth
        autoComplete={'new-password'}
        sx={{ maxWidth: 300 }}
      />
      <CenterBox sx={{ height: 40 }}>
        <Typography variant={'body2'} color={'text.secondary'} sx={{ maxWidth: 300 }}>
          {isFailed
            ? '❌ Failed signing in to the pool due to wrong password or unregistered account.'
            : 'ℹ️ If you set a different password for this pool, please find it from file pool.account.'}
        </Typography>
      </CenterBox>
      <Button variant={'contained'} disabled={!password || isWorking} onClick={handleSubmit} sx={{ width: 120 }}>
        {isWorking ? 'Signing In...' : 'Sign In'}
      </Button>
    </Stack>
  );
}
