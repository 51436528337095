import { capitalize } from '@mui/material';

export * from './common';
export * from './state';
export * from './pool';

export function getPoolTitle(poolname: string, longTitle = false) {
  const formalPoolname = capitalize(poolname);
  return longTitle ? `Pool - ${formalPoolname}` : formalPoolname;
}
