import axios, { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { env } from './common';
import { useUserToken } from './state';

export class PoolAPI {
  name: string;
  proxy: AxiosInstance;

  constructor(poolname?: string) {
    this.name = poolname ?? env.defaultPoolname;
    this.proxy = axios.create();
    this.proxy.defaults.baseURL = env.pools[this.name].apiBase;
    this.proxy.defaults.headers.post['Content-Type'] = 'application/json';
  }

  private usePrivateQuery = (queryKey: any, fetchFn: any) => {
    const jwt = useUserToken(this.name);
    return useQuery(queryKey, () => fetchFn(jwt).then((res: any) => res.data), {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(jwt),
    });
  };

  private makeConfig = (jwt: string) => {
    return { headers: { Authorization: `Bearer ${jwt}` } };
  };

  signin = async (username: string, password: string, actionToken: string) => {
    return this.proxy.post('/signin', { username, password, actionToken });
  };

  signup = async (username: string, password: string, actionToken: string) => {
    return this.proxy.post('/signup', { username, password, actionToken });
  };

  getUserProfile = async (jwt: string) => {
    return this.proxy.get('/user', this.makeConfig(jwt));
  };

  useUserProfile = () => {
    return this.usePrivateQuery([this.name, 'user-profile'], this.getUserProfile);
  };

  getUserMiningInfos = async (jwt: string) => {
    return this.proxy.get('/user-mining-infos', this.makeConfig(jwt));
  };

  useUserMiningInfos = () => {
    return this.usePrivateQuery([this.name, 'user-mining-infos'], this.getUserMiningInfos);
  };

  getMinedBlockInfos = async (jwt: string) => {
    return this.proxy.get('/mined-block-infos', this.makeConfig(jwt));
  };

  useMinedBlockInfos = () => {
    return this.usePrivateQuery([this.name, 'mined-block-infos'], this.getMinedBlockInfos);
  };

  changeUserAddress = async (jwt: string, value: string) => {
    return this.proxy.post('/address', { value }, this.makeConfig(jwt));
  };

  resetUserAddress = async (jwt: string, username: string) => {
    return this.proxy.post('/reset-address', { username }, this.makeConfig(jwt));
  };
}

const poolAPIs = Object.fromEntries(
  Object.keys(env.pools).map((poolname: string) => [poolname, new PoolAPI(poolname)])
);

export function getPoolAPI(poolname?: string) {
  return poolAPIs[poolname ?? env.defaultPoolname];
}

export const apiV1 = getPoolAPI();
