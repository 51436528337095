import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { env, getPoolTitle, useSignedinPoolnames } from '../../api';
import GlobalOverview from './GlobalOverview';
import PoolOverview from './PoolOverview';
import PoolSignIn from './PoolSignIn';

const centerSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 1,
  width: 1,
};

export default function MultiPoolDashboard() {
  const navigate = useNavigate();
  const signedinPoolnames = useSignedinPoolnames();
  const [showAllPools, setShowAllPools] = React.useState(false);

  React.useEffect(() => {
    document.title = env.title + ' | Dashboard';
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
          Dashboard
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={'Overview'}
            action={
              signedinPoolnames.length < env.poolnames.length ? (
                <FormControlLabel
                  control={<Switch onChange={(event) => setShowAllPools(event.target.checked)} />}
                  label={<Typography color={'appBlue.light'}>Show All Pools</Typography>}
                  labelPlacement={'start'}
                />
              ) : null
            }
          />
          <CardContent sx={{ ...centerSx, height: 300 }}>
            <GlobalOverview />
          </CardContent>
        </Card>
      </Grid>
      {(showAllPools ? env.poolnames : signedinPoolnames).map((pn: string) => (
        <Grid key={pn} item xs={12} sm={!showAllPools && signedinPoolnames.length < 2 ? 12 : 6}>
          <Card>
            <CardHeader
              title={getPoolTitle(pn, true)}
              action={
                <Button onClick={() => navigate(`/account/pool/${pn}`)} sx={{ color: 'appBlue.light' }}>
                  {'View Details >'}
                </Button>
              }
            />
            <CardContent sx={{ ...centerSx, height: 220 }}>
              {signedinPoolnames.includes(pn) ? (
                <PoolOverview poolname={pn} />
              ) : (
                <PoolSignIn poolname={pn} sx={{ marginTop: -2, width: 1 }} />
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
