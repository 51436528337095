import React from 'react';
import { Stack, Typography, Divider, List, ListItem } from '@mui/material';
import { StyledSmartLink } from '../../components/common';
import { environment as env } from '../../environments/environment';
import { releaseHistory } from './release-history';

const DOWNLOAD_BASEURL = 'https://download.abelian.info/release/pool';
function getDownloadLink(os: 'linux' | 'windows' | 'macos', arch: 'amd64' | 'arm64') {
  const suffix = env.multipool || env.poolServiceName === 'abel' ? '' : '-' + env.poolServiceName;
  const ext = os === 'windows' ? '.zip' : '.tar.gz';
  return `${DOWNLOAD_BASEURL}/abe-miningpool-client-${os}-${arch}-v${env.latestVersion}${suffix}${ext}`;
}

function Subtitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant={'h5'} sx={{ mt: 2, mb: 1, color: 'appBlue.lightest' }}>
      {children}
    </Typography>
  );
}

export function Download() {
  React.useEffect(() => {
    document.title = env.title + ' | Download';
  }, []);

  return (
    <Stack>
      <Typography variant={'h4'} color={'appBlue.lightest'}>
        Latest Release (v{env.latestVersion})
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant={'h6'}>
        Please choose the package matching your operating system and processor architecture:
      </Typography>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            pl: 1,
          },
          mb: 4,
        }}
      >
        <ListItem>
          <StyledSmartLink to={getDownloadLink('linux', 'amd64')}>
            Package for Linux on Intel x86 or AMD64
          </StyledSmartLink>
        </ListItem>
        <ListItem sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <StyledSmartLink to={getDownloadLink('windows', 'amd64')}>
            Package for Windows on Intel x86 or AMD64
          </StyledSmartLink>
        </ListItem>
        <ListItem>
          <StyledSmartLink to={getDownloadLink('macos', 'amd64')}>
            Package for macOS on Intel x86 or AMD64
          </StyledSmartLink>
        </ListItem>
        <ListItem>
          <StyledSmartLink to={getDownloadLink('macos', 'arm64')}>
            Package for macOS on ARM64 (Apple Silicon M1 {'&'} M2)
          </StyledSmartLink>
        </ListItem>
      </List>

      <Typography variant={'h4'} color={'appBlue.lightest'}>
        Release History
      </Typography>
      <Divider sx={{ mt: 2, mb: 1 }} />
      {releaseHistory.map((d) => {
        if (d.version > env.latestVersion) {
          return null;
        }
        const light = d.version === env.latestVersion ? '🟢' : '🟡';
        return (
          <React.Fragment key={d.version}>
            <section>
              <Subtitle>
                {light} {d.version}
              </Subtitle>
              {d.notes.map((dd, idx) => (
                <Typography key={`${d.version}-${idx}`}>- {dd}</Typography>
              ))}
            </section>
          </React.Fragment>
        );
      })}
    </Stack>
  );
}

export default Download;
