import { formatDuration, formatRFC7231 } from 'date-fns';
import locale_en_US from 'date-fns/locale/en-US';
import { KeyedObject } from './types';

const formatDistanceLocale: KeyedObject = {
  lessThanXSeconds: '{{count}} secs',
  xSeconds: '{{count}} secs',
  halfAMinute: '30 secs',
  lessThanXMinutes: '{{count}} mins',
  xMinutes: '{{count}} mins',
  aboutXHours: '{{count}} hrs',
  xHours: '{{count}} hrs',
  xDays: '{{count}} days',
  aboutXWeeks: '{{count}} weeks',
  xWeeks: '{{count}} weeks',
  aboutXMonths: '{{count}} mons',
  xMonths: '{{count}} mons',
  aboutXYears: '{{count}} yrs',
  xYears: '{{count}} yrs',
  overXYears: '{{count}} yrs',
  almostXYears: '{{count}} yrs',
};

const formatDistance = (token: string, count: number, options: any) => {
  options = options || {};
  const result = String(formatDistanceLocale[token]).replace(
    '{{count}}',
    String(count)
  );
  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }
  return result;
};

const locale_en_US_abbr = {
  ...locale_en_US,
  formatDistance,
};

export const formatDurationAbbr = (
  start: Date | number,
  end: Date | number,
  numUnits: 1 | 2 = 2
) => {
  const t1 = typeof start === 'number' ? start : start.getTime();
  const t2 = typeof end === 'number' ? end : end.getTime();

  let dt = Math.abs(t2 - t1);
  const days = Math.floor(dt / (1000 * 60 * 60 * 24));
  dt = dt - days * (1000 * 60 * 60 * 24);
  const hours = Math.floor(dt / (1000 * 60 * 60));
  dt = dt - hours * (1000 * 60 * 60);
  const minutes = Math.floor(dt / (1000 * 60));
  dt = dt - minutes * (1000 * 60);
  const seconds = Math.floor(dt / 1000);
  const duration = { years: 0, months: 0, days, hours, minutes, seconds };

  let format: string[];
  if (days > 0) {
    format = numUnits === 1 ? ['days'] : ['days', 'hours'];
  } else if (hours > 0) {
    format = numUnits === 1 ? ['hours'] : ['hours', 'minutes'];
  } else if (minutes > 0) {
    format = numUnits === 1 ? ['minutes'] : ['minutes', 'seconds'];
  } else {
    format = ['seconds'];
  }

  return formatDuration(duration, { format, locale: locale_en_US_abbr });
};

export { formatRFC7231 };