export const releaseHistory = [
  {
    version: '0.11.5-b',
    notes: [
      'Bugfix: Fixed a bug regarding rewards allocation.',
    ],
  },
  {
    version: '0.11.5',
    notes: [
      'New Feature: Support distributing ABEL to client addresses.',
    ],
  },
  {
    version: '0.11.3',
    notes: [
      'New Feature: Support multiple mining pool servers.',
      'Enhancement: Code optimization.',
    ],
  },
  {
    version: '0.11.2',
    notes: [
      'The first public release.',
    ],
  }
];