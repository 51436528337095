import { useRecoilState, useRecoilValue } from 'recoil';
import { persistentAtom } from '../abelcommon';
import { env } from './common';

export type UserState = {
  poolname?: string;
  username?: string;
  signinAt?: number;
  token?: string;
};

export type UserStates = {
  [key: string]: UserState;
};

function initUserStates() {
  return Object.fromEntries(
    env.poolnames.map((poolname: string) => [
      poolname,
      persistentAtom<UserState>({
        key: `pool-user-state-${poolname}`,
        default: { poolname: poolname, username: null, signinAt: null, token: null },
      }),
    ])
  );
}

const userStates = initUserStates();

export function useUserState(poolname?: string) {
  return useRecoilState<UserState>(userStates[poolname ?? env.defaultPoolname]);
}

export function useSignedinUserStateValues() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userStateValues = env.poolnames.map((pn: string) => useRecoilValue<UserState>(userStates[pn]));
  return userStateValues.filter((us: UserState) => Boolean(us.token));
}

export function useSignedinPoolnames() {
  const signedinUserStateValues = useSignedinUserStateValues()
  return signedinUserStateValues.map((v: UserState) => v.poolname);
}

export function useUsername() {
  const signedinUserStateValues = useSignedinUserStateValues()
  return signedinUserStateValues.length > 0 ? signedinUserStateValues[0].username : null;
}

export function useUserToken(poolname?: string) {
  const state = useRecoilValue<UserState>(userStates[poolname ?? env.defaultPoolname]);
  return state.token ?? '';
}

export function useUserSignedIn() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userTokens = env.poolnames.map((pn: string) => useUserToken(pn));
  return userTokens.some((token: string) => token);
}

export function usePoolSignedIn(poolname: string) {
  const token = useUserToken(poolname);
  return Boolean(token);
}