import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ButtonProps,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { KeyedObject } from '../utils';

const VeryDenseButton = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  return <Button ref={ref} {...props} sx={{ minHeight: 0, minWidth: 0, p: 0, ...props.sx }} />;
});

export interface SimpleTaskDialogProps extends KeyedObject {
  initOpen: boolean;
  onClose: () => void;
  prepare?: () => string;
  work?: () => string | Promise<string>;
  title?: string;
  text?: string;
  workingText?: string;
  successText?: string;
  cancelButtonProps?: any;
  submitButtonProps?: any;
  doneButtonProps?: any;
  content: React.ReactNode | React.ReactNode[] | ((step: string) => React.ReactNode | React.ReactNode[]);
}

export const SimpleTaskDialog = React.forwardRef(
  (
    {
      initOpen,
      onClose,
      prepare = () => '',
      work = () => '',
      title = '',
      text = '',
      workingText = 'Working in progress, please wait...',
      successText = 'Task finished successfully.',
      cancelButtonProps,
      submitButtonProps,
      doneButtonProps,
      content,
      ...others
    }: SimpleTaskDialogProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [step, setStep] = React.useState<'PREPARE' | 'WORKING' | 'DONE'>('PREPARE');
    const [error, setError] = React.useState('');

    const handleClose = () => {
      setError('');
      setStep('PREPARE');
      onClose();
    };

    const handleSubmit = async () => {
      if (step !== 'PREPARE') {
        return;
      }

      const prepareError = await prepare();
      if (prepareError) {
        setError(prepareError);
        return;
      }

      setError('');
      setStep('WORKING');
      Promise.resolve(work())
        .then((workError) => {
          if (workError) {
            setError(workError);
            setStep('PREPARE');
          } else {
            setStep('DONE');
          }
        })
        .catch((error) => {
          console.error(error);
          setError('An internal error has occurred.');
          setStep('PREPARE');
        });
    };

    return (
      <Dialog ref={ref} open={initOpen} onClose={() => null} fullScreen {...others}>
        <DialogTitle>
          <Stack direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            {title}
            <VeryDenseButton onClick={handleClose} sx={{ color: 'text.secondary' }}>
              <CloseIcon />
            </VeryDenseButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {typeof content !== 'function' && content}
          {typeof content === 'function' && content(step)}
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 2 }}>
          <Box sx={{ mr: 'auto' }}>
            {step === 'PREPARE' && error && <Typography color={'error'}>{`❌ ${error}`}</Typography>}
            {step === 'WORKING' && <Typography color={'text.main'}>{`⌛ ${workingText}`}</Typography>}
            {step === 'DONE' && <Typography color={'text.main'}>{`✅ ${successText}`}</Typography>}
          </Box>
          {step === 'PREPARE' && (
            <Button variant={'outlined'} onClick={handleClose} children={'Cancel'} {...cancelButtonProps} />
          )}
          {step !== 'DONE' && (
            <Button
              variant={'contained'}
              onClick={handleSubmit}
              disabled={step === 'WORKING'}
              children={'Submit'}
              {...submitButtonProps}
            />
          )}
          {step === 'DONE' && (
            <Button variant={'contained'} onClick={handleClose} children={'Done'} {...doneButtonProps} />
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default SimpleTaskDialog;
