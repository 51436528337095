import React from 'react';
import { atom, useRecoilState } from 'recoil';
import {
  DrawerProps,
  CssBaseline,
  Breakpoint,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  Drawer,
  Stack,
  Box,
  IconButton,
  Theme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export type LayoutState = {
  isDesktop: boolean;
  desktopDrawerOpen: boolean;
  mobileDrawerOpen: boolean;
};

export const layoutState = atom<LayoutState>({
  key: 'layout-state',
  default: null,
});

export function useLayoutState() {
  return useRecoilState(layoutState);
}

export interface BarOptions {
  desktopHeight?: number;
  mobileHeight?: number;
  alwayOnTop?: boolean;
}

export type DrawerSide = 'left' | 'right' | 'none';

export interface DrawerOptions {
  mode?: DrawerProps['variant'] | 'none';
  side?: 'left' | 'right';
  width?: number;
  initOpen?: boolean;
}

export interface BarDrawerLayoutProps {
  breakpoint?: Breakpoint;
  barOptions?: BarOptions;
  desktopDrawerOptions?: DrawerOptions;
  mobileDrawerOptions?: DrawerOptions;
  header?: React.ReactNode;
  main?: React.ReactNode;
  footer?: React.ReactNode;
  sideNav?: React.ReactNode;
  bottomNav?: React.ReactNode;
  children?: React.ReactNode;
}

export function BarDrawerLayout({
  breakpoint = 'sm',
  barOptions = {},
  desktopDrawerOptions = {},
  mobileDrawerOptions = {},
  header,
  main,
  footer,
  sideNav,
  bottomNav,
  children,
}: BarDrawerLayoutProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(breakpoint));
  const [layoutState, setLayoutState] = useLayoutState();

  React.useEffect(() => {
    setLayoutState({
      isDesktop,
      desktopDrawerOpen: desktopDrawerOptions.initOpen ?? true,
      mobileDrawerOpen: mobileDrawerOptions.initOpen ?? false,
    });
  }, [desktopDrawerOptions.initOpen, isDesktop, mobileDrawerOptions.initOpen, setLayoutState]);

  if (layoutState === null) {
    return null;
  }

  const drawerIsPermanent = isDesktop && desktopDrawerOptions.mode === 'permanent';
  const drawerMode = isDesktop ? desktopDrawerOptions.mode ?? 'persistent' : mobileDrawerOptions.mode ?? 'temporary';
  const drawerIsNone = drawerMode === 'none';
  const drawerSide = isDesktop ? desktopDrawerOptions.side ?? 'right' : mobileDrawerOptions.side ?? 'right';
  const drawerWidth = isDesktop ? desktopDrawerOptions.width ?? 240 : mobileDrawerOptions.width ?? 240;
  const drawerOpen =
    !drawerIsNone && (isDesktop ? drawerIsPermanent || layoutState.desktopDrawerOpen : layoutState.mobileDrawerOpen);

  const barHeight = (isDesktop ? barOptions.desktopHeight : barOptions.mobileHeight) ?? 48;
  const barAlwaysOnTop = isDesktop ? false : barOptions.alwayOnTop ?? false;
  const barWidth = isDesktop && drawerOpen ? `calc(100% - ${drawerWidth}px)` : 1;
  const barLeft = isDesktop && drawerOpen && drawerSide === 'left' ? drawerWidth : 0;
  const barZIndexSx = barAlwaysOnTop ? { zIndex: (theme: Theme) => theme.zIndex.drawer + 1 } : {};

  const setDrawerOpen = (value: boolean) => {
    if (isDesktop) {
      setLayoutState({ ...layoutState, desktopDrawerOpen: value });
    } else {
      setLayoutState({ ...layoutState, mobileDrawerOpen: value });
    }
  };

  const DrawerToggleBox = () => {
    if (drawerIsNone || drawerIsPermanent) {
      return null;
    }

    const ToggleButton = drawerOpen ? (drawerSide === 'left' ? ChevronLeftIcon : ChevronRightIcon) : MenuIcon;
    return (
      <Box
        sx={{
          width: barHeight,
          height: barHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)} sx={{ color: 'inherit', edge: 'end', borderRadius: 0 }}>
          <ToggleButton sx={{ fontSize: barHeight * 0.4 }} />
        </IconButton>
      </Box>
    );
  };

  const DrawerBox = () => {
    if (drawerIsNone) {
      return null;
    }

    return (
      <Box
        component={'nav'}
        sx={{
          display: drawerOpen ? 'block' : 'none',
          width: { sm: drawerWidth },
          flexShrink: 0,
        }}
      >
        <Drawer
          anchor={drawerSide}
          PaperProps={{ sx: { width: drawerWidth } }}
          variant={drawerMode}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {barAlwaysOnTop && <Box sx={{ height: barHeight }} />}
          {sideNav}
        </Drawer>
      </Box>
    );
  };

  return (
    <Box sx={{ width: 1, display: 'flex' }}>
      <CssBaseline />
      <AppBar position={'fixed'} sx={{ top: 0, left: barLeft, width: barWidth, height: barHeight, ...barZIndexSx }}>
        <Toolbar variant={'dense'} disableGutters sx={{ minHeight: barHeight, height: barHeight }}>
          {drawerSide === 'left' && <DrawerToggleBox />}
          <Box sx={{ width: 1, height: 1 }}>{header}</Box>
          {drawerSide === 'right' && <DrawerToggleBox />}
        </Toolbar>
      </AppBar>
      {drawerSide === 'left' && <DrawerBox />}
      <Box sx={{ width: 1, flexGrow: 1 }}>
        <Stack sx={{ minHeight: { xs: '-webkit-fill-available', sm: '100vh' } }}>
          <Box sx={{ height: barHeight }} />
          <Box component={'main'} sx={{ width: 1 }}>
            {main ?? children}
          </Box>
          <Box component={'footer'} sx={{ width: 1, mt: 'auto' }}>
            {footer}
          </Box>
        </Stack>
        <Box component={'nav'} sx={{ width: 1 }}>
          {bottomNav}
        </Box>
      </Box>
      {drawerSide === 'right' && <DrawerBox />}
    </Box>
  );
}

export default BarDrawerLayout;
