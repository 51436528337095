import React from 'react';
import { useLocation } from 'react-router-dom';
import { BoxProps, Box, Typography, SxProps, Divider } from '@mui/material';

export const CenterBox = React.forwardRef((props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
  return (
    <Box
      ref={ref}
      {...props}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 1, height: 1, ...props.sx }}
    />
  );
});

export function XBox({ text, sx, children }: { text?: string; sx?: SxProps; children?: React.ReactNode }) {
  return (
    <CenterBox sx={sx}>
      {text && <Typography variant={'h6'}>{text}</Typography>}
      {children}
    </CenterBox>
  );
}

export function PBox() {
  const location = useLocation();
  return (
    <XBox sx={{ flexDirection: 'column', minHeight: 300 }}>
      <Typography variant={'h5'}>Page Not Found</Typography>
      <Divider sx={{ width: 300, my: 1 }} />
      <Typography color={'text.secondary'}>path: {location.pathname}</Typography>
    </XBox>
  );
}

export default CenterBox;
