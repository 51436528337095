import React from 'react';
import { useParams } from 'react-router-dom';
import { env } from '../../api';
import MultiPoolDashboard from './MultiPoolDashboard';
import SinglePoolDashboard from './SinglePoolDashboard';

export default function Dashboard() {
  const { poolname } = useParams();
  if (poolname || !env.multipool) {
    return <SinglePoolDashboard poolname={poolname ?? env.defaultPoolname} />;
  } else {
    return <MultiPoolDashboard />;
  }
}