export const environment: any = {
  production: false,
  apiBase: '/v1/pool',
  recaptchaV3: {
    enabled: false,
    siteKey: '6LfGTQYiAAAAAFS7QPi9hRX_Koia306635kS6Cnr',
    secretKey: '6LfGTQYiAAAAAPxFc8B49fqCIiuvlavIDIJqyMvW',
  },
  enableSignup: false,
  poolServiceName: 'test',
  poolType: 'cpu',
  latestVersion: '0.11.3',
  title: 'TestPool',
  subtitle: 'A mining pool for the Abelian Blockchain',

  multipool: null,
};