import React from "react";
import {
  Stack,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  Link,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import { keyframes } from "@mui/system";
import { Emphasis, StyledSmartLink, CodeBox } from "../components/common";
import { env, useUserState } from "../api";

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`;

export function Home() {
  const [userState] = useUserState();

  React.useEffect(() => {
    document.title = env.title + " | Home";
  }, []);

  return (
    <Stack>
      <Box
        sx={{
          backgroundColor: "rgba(255, 82, 82, 0.4)",
          color: "#ffffff",
          mb: 2,
          p: 2,
          border: "2px solid #ff5252",
          borderRadius: 2,
          position: "relative",
          animation: `${pulseAnimation} 2s infinite`,
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Warning sx={{ color: "#ff5252", fontSize: 40, mr: 2, mt: 1 }} />
        <Box>
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 1, fontWeight: "bold", color: "#ff5252" }}
          >
            Important Notice
          </Typography>
          <Typography component="ul" sx={{ pl: 2, "& li": { mb: 1 } }}>
            <li>
              This site is the old and legacy mining pool. Alicia, Baker,
              Charlie and Dior servers are gradually deprecating. All the
              remaining balance of each miner will be dispatched to the miner
              shortly after each server is shut down.
            </li>
            <li>
              Please visit the new site{" "}
              <Link
                href="https://maxpool.org"
                color="inherit"
                underline="always"
                sx={{ color: "#ff5252" }}
              >
                here
              </Link>
              .
            </li>
            <li>
              The new site called Maxpool is a lot more powerful and user
              friendly. Miners can get rewards much faster with much lower
              threshold too. It's easy to set up!
            </li>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "appBlue.dark",
          mb: 2,
          p: 1,
          border: 1,
          borderColor: "warning.main",
          borderRadius: 2,
        }}
      >
        {!userState.token && (
          <Typography variant={"h6"}>
            <Emphasis>NOTE: </Emphasis>If you have already started the pooled
            mining client, please Sign In to view your account data. The
            username and password can be found from file pool.account under the
            client path.
          </Typography>
        )}
        {userState.token && (
          <Typography variant={"h6"}>
            <Emphasis>NOTE: </Emphasis>You have already signed in. Please go to
            the{" "}
            <StyledSmartLink to={"/account/dashboard"}>
              Dashboard
            </StyledSmartLink>{" "}
            to view your account data.
          </Typography>
        )}
      </Box>
      <Typography variant={"h6"}>
        Start mining Abelian with MabelPools in 10 minutes:
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant={"h4"} color={"appBlue.lightest"} sx={{ mb: 1 }}>
        GPU Mining
      </Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          "& .MuiListItem-root": {
            display: "list-item",
            pl: 1,
          },
        }}
      >
        <ListItem>
          <Emphasis>
            <em>For Windows platforms: </em>
          </Emphasis>
          We recommend using the{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelminer-windows-amd64-v2.0.2.exe"
            }
          >
            GUI Installer
          </StyledSmartLink>{" "}
          to install the latest release of GPU pool mining client for Windows.
          It will guide you to install and configure the client in a few clicks.
          Please refer to{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelminer-win-guide-v1.0.0-en.pdf"
            }
          >
            Installation Guide for Windows
          </StyledSmartLink>{" "}
          or{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelminer-win-guide-v1.0.0-cn.pdf"
            }
          >
            Windows 挖矿客户端安裝指南
          </StyledSmartLink>{" "}
          for more details.
          <br />
        </ListItem>
        <ListItem>
          <Emphasis>
            <em>For Linux and HiveOS platforms: </em>
          </Emphasis>
          We recommend following the instructions in{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelminer-linux-setup-guide-en.pdf"
            }
          >
            Installation Guide for Linux
          </StyledSmartLink>{" "}
          or{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelminer-linux-setup-guide-cn.pdf"
            }
          >
            Linux 挖矿客户端安装指南
          </StyledSmartLink>{" "}
          to setup the environment and start mining on Linux and HiveOS.
          <br />
          <br />
          We also provide a{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelian-mining.tar.gz"
            }
          >
            Helper Script
          </StyledSmartLink>{" "}
          to help you manage the client more easily after installation. Please
          refer to{" "}
          <StyledSmartLink
            to={
              "https://download.abelian.info/release/pool/easy-client/abelminer-linux-guide-v1.1-en.pdf"
            }
          >
            Abelminer Helper Script Guide
          </StyledSmartLink>{" "}
          for more details.
        </ListItem>
      </List>
      <Divider sx={{ my: 2 }} />
      <Typography variant={"h4"} color={"appBlue.lightest"} sx={{ mb: 1 }}>
        CPU Mining
      </Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          "& .MuiListItem-root": {
            display: "list-item",
            pl: 1,
          },
        }}
      >
        <ListItem>
          <Emphasis>
            <em>Prerequisite: </em>
          </Emphasis>
          Generate an Abelian blockchain address to receive pooled mining
          rewards.
          <br />
          If you have mined ABEL using Abelian Core before, you may reuse the
          previous coinbase address. If you are new to mining ABEL, please
          follow the first two sections in this{" "}
          <StyledSmartLink
            to={"https://docs.abelian.info/docs/mining/installation/"}
          >
            Guide
          </StyledSmartLink>{" "}
          (Installation {"&"} Create a wallet) to create your first Abelian
          wallet and generate a new address.
        </ListItem>
        <ListItem>
          <Emphasis>Step 1: </Emphasis>Go to the{" "}
          <StyledSmartLink
            to={
              "https://www.abelian.info/downloads/#4-abelian-cpu-mining-pool-client"
            }
          >
            Downloads
          </StyledSmartLink>{" "}
          page to obtain the lastest release of CPU mining pool client matching
          your platform and extract it to your preferred path.
        </ListItem>
        <ListItem>
          <Emphasis>Step 2: </Emphasis>Edit miningpool-client.conf by adding the
          following line: <br />
          <CodeBox>address=YOUR_WALLET_ADDRESS</CodeBox>
        </ListItem>
        <ListItem>
          <Emphasis>Step 3: </Emphasis>Run abe-miningpool-client to start
          mining. You will be asked to set a password at the first run of the
          client. If you have multiple machines runing the client, we strongly
          suggest using the same password across all machines.
        </ListItem>
        <ListItem>
          <Emphasis>Step 4: </Emphasis>Sign In on this website to view your
          account data. The username and password can be found from file
          pool.account under the client path.
        </ListItem>
      </List>
    </Stack>
  );
}

export default Home;
