import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Stack,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  IconButton,
  Typography,
  Link,
} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CopyrightIcon from '@mui/icons-material/Copyright';
import PublicIcon from '@mui/icons-material/Public';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DirectionsIcon from '@mui/icons-material/Directions';
import { environment as env } from '../environments/environment';
import { useLayoutState } from '../components/BarDrawerLayout';
import { getPoolTitle, useUserSignedIn } from '../api';
import SignUpInOut from './SignUpInOut';
import SignInOutMultipool from './SignInOutMultipool';

const categories = [
  {
    id: 'Account',
    children: [
      { id: 'Dashboard', icon: <DashboardIcon />, to: '/account/dashboard', requireSignIn: true },
      ...(env.multipool ? env.multipool.pools : []).map((pool: any) => ({
        id: getPoolTitle(pool.name, true),
        icon: <DirectionsIcon />,
        to: `/account/pool/${pool.name}`,
        requireSignIn: true,
      })),
    ],
  },
  {
    id: 'Links',
    children: [
      { id: 'Official Website', icon: <CopyrightIcon />, to: 'https://www.abelian.info' },
      { id: 'Blockchain Explorer', icon: <PublicIcon />, to: 'https://explorer.abelian.info' },
      { id: 'Documents', icon: <ArticleIcon />, to: 'https://docs.abelian.info' },
    ],
  },
];

function isExternalURL(url: string) {
  return url.startsWith('http://') || url.startsWith('https://');
}

export function Navigator() {
  const navigate = useNavigate();
  const location = useLocation();
  const [layoutState, setLayoutState] = useLayoutState();
  const signedin = useUserSignedIn();

  const closeMobileDrawer = () => {
    if (!layoutState.isDesktop) {
      setLayoutState({ ...layoutState, mobileDrawerOpen: false });
    }
  };

  const jump = (to: string) => {
    if (isExternalURL(to)) {
      window.open(to, '_blank');
    } else {
      navigate(to);
    }
    setTimeout(closeMobileDrawer, 600);
  };

  return (
    <Stack sx={{ minHeight: { xs: '-webkit-fill-available', sm: '100vh' } }}>
      <List
        disablePadding
        sx={{
          mb: layoutState.isDesktop ? 4 : 2,
          '& .Mui-selected': { color: 'appBlue.lightest', backgroundColor: 'appGlass.main' },
        }}
      >
        <ListItem sx={{ height: layoutState.isDesktop ? 60 : 48, px: 0.5, justifyContent: 'right' }}>
          {env.multipool ? <SignInOutMultipool /> : <SignUpInOut />}
          {!layoutState.isDesktop && (
            <IconButton onClick={closeMobileDrawer} sx={{ edge: 'end', color: 'inherit', borderRadius: 0 }}>
              <ChevronRightIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </ListItem>
        <Box sx={{ backgroundColor: 'appBlue.main' }}>
          <ListItem disablePadding>
            <ListItemButton
              selected={location.pathname === '/home'}
              sx={{ py: 1, px: 2, color: 'text.secondary' }}
              onClick={() => jump('/home')}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                <HomeRoundedIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton
              selected={location.pathname === '/download'}
              sx={{ py: 1, px: 2, color: 'text.secondary' }}
              onClick={() => jump('/download')}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </ListItemButton>
          </ListItem> */}
          <Divider />
        </Box>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ backgroundColor: 'appBlue.main' }}>
            <ListItem sx={{ py: layoutState.isDesktop ? 1 : 0.5, px: 2 }}>
              <ListItemText sx={{ color: 'appGray.lightest' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, to, requireSignIn = false }: any) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton
                  selected={to === location.pathname}
                  sx={{ py: 0.5, px: 2, color: 'text.secondary' }}
                  onClick={() => jump(to)}
                  disabled={requireSignIn && !signedin}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                  {isExternalURL(to) && <OpenInNewIcon sx={{ width: 14, height: 14 }} />}
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
          </Box>
        ))}
      </List>
      <Stack sx={{ mt: 'auto', mb: 1, width: 1, alignItems: 'center' }}>
        <Typography variant={'body2'}>{`${env.title} © ${new Date().getFullYear()}`}</Typography>
        <Typography variant={'body2'}>
          Powered by{' '}
          <Link
            href={'https://abelian.info'}
            target={'_blank'}
            sx={{ color: 'appBlue.lightest', textDecoration: 'none' }}
          >
            Abelian Foundation
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}

export default Navigator;