import { environment } from '../environments/environment';

const envmp = environment.multipool;

export const env = {
  ...environment,
  pools: Object.fromEntries(
    (envmp
      ? envmp.pools
      : [{ name: environment.poolServiceName, type: environment.poolType, apiBase: environment.apiBase }]
    ).map((pool: any) => [pool.name, pool])
  ),
  poolnames: envmp ? envmp.pools.map((pool: any) => pool.name): [environment.poolServiceName],
  defaultPoolname: envmp ? envmp.pools[0].name : environment.poolServiceName,
};
