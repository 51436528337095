import React from 'react';
import { CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { formatDurationAbbr } from '../../abelcommon';
import { getPoolAPI } from '../../api';

export default function PoolOverview({ poolname }: { poolname: string }) {
  const api = getPoolAPI(poolname);
  const userProfileQuery = api.useUserProfile();

  if (!userProfileQuery.isSuccess) {
    return <CircularProgress />;
  }

  const d = userProfileQuery.data.payload;
  const balance = Number(d.balance) / 1e7;
  const period = formatDurationAbbr(new Date(d.createdAt), Date.now());

  return (
    <Stack spacing={1} alignItems={'center'}>
      <Typography color={'appGray.main'}>You have mined</Typography>
      <Tooltip title={balance.toLocaleString('en-US')} placement={'right'}>
        <Typography variant={'h4'} color={'orange'}>
          {balance.toLocaleString('en-US', { maximumFractionDigits: 2 })}
        </Typography>
      </Tooltip>
      <Typography variant={'h5'} color={'appBlue.light'}>
        ABEL
      </Typography>
      <Typography color={'appGray.main'}>within {period}.</Typography>
    </Stack>
  );
}
