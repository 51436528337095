import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Stack, Chip, TextField, InputAdornment, Button, Typography, Divider } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import { SimpleTaskDialog, delay, formatDurationAbbr } from '../abelcommon';
import { useLayoutState } from '../components/BarDrawerLayout';
import { env, useUserState, apiV1 } from '../api';
import { useGoogleRecaptchaV3, getRecaptchaToken } from './grechaptcha';

type UserAuthDialogName = 'signup' | 'signin' | 'signout';
const ActiveDialogContext = React.createContext<UserAuthDialogName>(null);

interface UserAuthDialogProps {
  setActiveDialog: (name: UserAuthDialogName) => void;
}

function UserSignInDialog({ setActiveDialog }: UserAuthDialogProps) {
  const activeDialog = React.useContext(ActiveDialogContext);
  const [, setUserState] = useUserState();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  let userData: any = null;

  return (
    <SimpleTaskDialog
      initOpen={activeDialog === 'signin'}
      onClose={() => {
        if (userData !== null) {
          setUsername('');
          setPassword('');
          setUserState(userData);
          navigate('/account/dashboard');
        }
        setActiveDialog(null);
      }}
      fullScreen={false}
      sx={{ '& .MuiPaper-root': { borderRadius: 2, minWidth: 360 } }}
      title={`Sign in to ${env.title}`}
      content={(step: string) => (
        <Stack spacing={2} sx={{ minWidth: 300 }}>
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-username'}
            label={'Username'}
            value={username}
            onChange={(e) => setUsername(e.target.value.replace(/\s/g, ''))}
            disabled={step !== 'PREPARE'}
            fullWidth
          />
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-password'}
            label={'Password'}
            type={'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={step !== 'PREPARE'}
            fullWidth
          />
          {env.enableSignup && (
            <Stack direction={'row'} alignItems={'center'}>
              <Typography>Or </Typography>
              <Button
                sx={{ color: 'appBlue.lightest' }}
                onClick={() => setActiveDialog('signup')}
                disabled={step !== 'PREPARE'}
              >
                Sign Up
              </Button>
              <Typography> to create a new account.</Typography>
            </Stack>
          )}
        </Stack>
      )}
      submitButtonProps={{ children: 'Sign In' }}
      cancelButtonProps={{ sx: { display: 'none' } }}
      doneButtonProps={{ children: 'Continue' }}
      workingText={'Signing in...'}
      successText={'Successfully signed in.'}
      prepare={() => {
        if (!username) {
          return 'Please input username.';
        } else if (username.includes(' ')) {
          return 'Invalid username.';
        } else if (!password) {
          return 'Please input password.';
        } else {
          return '';
        }
      }}
      work={async () => {
        try {
          const rechaptchaToken = await getRecaptchaToken();
          const res = await apiV1.signin(username, password, rechaptchaToken);
          if (res.status !== 200 || res.data.type !== 'signin') {
            console.error('Unexpected response: ' + JSON.stringify(res.data));
            return 'Unexpected response.';
          }
          userData = { username: username, signinAt: Date.now(), token: res.data.payload.jwt };
          return '';
        } catch (err: any) {
          console.error(err);
          return err.code;
        }
      }}
    />
  );
}

function UserSignUpDialog({ setActiveDialog }: UserAuthDialogProps) {
  const activeDialog = React.useContext(ActiveDialogContext);
  const [username, setUsername] = React.useState('');
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  let signedUp = false;

  return (
    <SimpleTaskDialog
      initOpen={activeDialog === 'signup'}
      onClose={() => {
        setUsername('');
        setPassword1('');
        setPassword2('');
        setActiveDialog(signedUp ? 'signin' : null);
      }}
      fullScreen={false}
      sx={{ '& .MuiPaper-root': { borderRadius: 2, minWidth: 360 } }}
      title={`Sign up for ${env.title}`}
      content={(step: string) => (
        <Stack spacing={2} sx={{ minWidth: 300 }}>
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-username'}
            label={'Username'}
            value={username}
            onChange={(e) => setUsername(e.target.value.replace(/\s/g, ''))}
            disabled={step !== 'PREPARE'}
            fullWidth
            autoComplete={'off'}
          />
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-password1'}
            label={'Password'}
            type={'password'}
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            disabled={step !== 'PREPARE'}
            fullWidth
            autoComplete={'new-password'}
          />
          <TextField
            variant={'standard'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
            margin={'dense'}
            id={'input-password2'}
            label={'Confirm'}
            type={'password'}
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            disabled={step !== 'PREPARE'}
            fullWidth
            autoComplete={'new-password'}
          />
          <Stack direction={'row'} alignItems={'center'}>
            <Typography>Or </Typography>
            <Button
              sx={{ color: 'appBlue.lightest' }}
              onClick={() => setActiveDialog('signin')}
              disabled={step !== 'PREPARE'}
            >
              Sign In
            </Button>
            <Typography> with an existing account.</Typography>
          </Stack>
        </Stack>
      )}
      submitButtonProps={{ children: 'Sign Up' }}
      cancelButtonProps={{ sx: { display: 'none' } }}
      doneButtonProps={{ children: 'Continue' }}
      workingText={'Signing up...'}
      successText={'Successfully signed up.'}
      prepare={() => {
        if (!username) {
          return 'Please input username.';
        } else if (username.includes(' ')) {
          return 'Invalid username.';
        } else if (!password1) {
          return 'Please input password.';
        } else if (password1 !== password2) {
          return "Passwords don't match.";
        } else {
          return '';
        }
      }}
      work={async () => {
        try {
          const rechaptchaToken = await getRecaptchaToken();
          const res = await apiV1.signup(username, password1, rechaptchaToken);
          if (res.status !== 200 || res.data.type !== 'signup') {
            console.error('Unexpected response: ' + JSON.stringify(res.data));
            return 'Unexpected response.';
          }
          signedUp = true;
          return '';
        } catch (err: any) {
          console.error(err);
          return err.code;
        }
      }}
    />
  );
}

function UserSignOutDialog({ setActiveDialog }: UserAuthDialogProps) {
  const activeDialog = React.useContext(ActiveDialogContext);
  const [userState, setUserState] = useUserState();
  const userProfileQuery = apiV1.useUserProfile();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let signedOut = false;

  return (
    <SimpleTaskDialog
      initOpen={activeDialog === 'signout'}
      onClose={() => {
        if (signedOut) {
          setUserState({});
          queryClient.resetQueries();
          queryClient.clear();
          navigate('/home');
        }
        setActiveDialog(null);
      }}
      fullScreen={false}
      sx={{ '& .MuiPaper-root': { borderRadius: 2, minWidth: 360 } }}
      title={`Sign out of ${env.title}`}
      content={(step: string) => {
        const question = <Typography color={'text.secondary'}>Are you going to exit the current account?</Typography>;
        if (!userProfileQuery.isSuccess || userProfileQuery.data.type !== 'user') {
          return question;
        }
        const d = userProfileQuery.data.payload;
        return (
          <>
            <Typography color={'text.secondary'}>Your account name is {d.username}.</Typography>
            {d.createdAt && <Typography color={'text.secondary'}>Signed-up at {d.createdAt}.</Typography>}
            <Typography color={'text.secondary'}>
              Signed-in at {formatDurationAbbr(userState.signinAt, Date.now())} ago.
            </Typography>
            <Divider sx={{ my: 1 }} />
            {question}
          </>
        );
      }}
      submitButtonProps={{ children: 'Sign Out', variant: 'outlined' }}
      cancelButtonProps={{ children: 'No, I will stay signed in.', variant: 'contained' }}
      doneButtonProps={{ children: 'Continue' }}
      workingText={'Signing out...'}
      successText={'Successfully signed out.'}
      work={async () => {
        await delay(1000);
        signedOut = true;
        return '';
      }}
    />
  );
}

export function SignUpInOut() {
  const [layoutState] = useLayoutState();
  const [userState] = useUserState();
  const [activeDialog, setActiveDialog] = React.useState<UserAuthDialogName>(null);

  useGoogleRecaptchaV3();

  const chipButton = userState.token ? (
    <Chip
      label={userState.username}
      onClick={() => setActiveDialog('signout')}
      sx={{
        maxWidth: layoutState.isDesktop ? 220 : 190,
        mr: layoutState.isDesktop ? 1.5 : 0.5,
        fontSize: userState.username.length > 20 ? '0.7em' : '0.8em',
      }}
    />
  ) : (
    <Chip label={'Sign In'} onClick={() => setActiveDialog('signin')} sx={{ mr: layoutState.isDesktop ? 1.5 : 0.5 }} />
  );

  const dialogs = userState.token ? (
    <UserSignOutDialog setActiveDialog={setActiveDialog} />
  ) : (
    <>
      <UserSignUpDialog setActiveDialog={setActiveDialog} />
      <UserSignInDialog setActiveDialog={setActiveDialog} />
    </>
  );

  return (
    <ActiveDialogContext.Provider value={activeDialog}>
      {chipButton}
      {dialogs}
    </ActiveDialogContext.Provider>
  );
}

export default SignUpInOut;
