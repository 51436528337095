import { environment as defaultEnv } from "./environment.default";

export const environment = {
  ...defaultEnv,
  production: true,
  apiBase: null,
  poolServiceName: 'mabel',
  poolType: 'multi',
  latestVersion: '0.11.5',
  title: 'MabelPools',
  subtitle: 'Federated Mining Pools for Abelian',

  multipool: {
    pools: [
      {
        name: 'alicia',
        type: 'cpu+gpu',
        apiBase: 'https://pool-alicia.abelian.info/v1/pool',
      },
      {
        name: 'baker',
        type: 'cpu+gpu',
        apiBase: 'https://pool-baker.abelian.info/v1/pool',
      },
      {
        name: 'charlie',
        type: 'cpu+gpu',
        apiBase: 'https://gpool-charlie.abelian.info/v1/pool',
      },
      {
        name: 'dior',
        type: 'cpu+gpu',
        apiBase: 'https://gpool-dior.abelian.info/v1/pool',
      },
    ],
  },
};
