import { createTheme, PaletteColor, SimplePaletteColorOptions, ThemeOptions } from '@mui/material';
import { blue } from '@mui/material/colors';

export interface AppPaletteColor extends PaletteColor {
  secondary?: string;
  darker?: string;
  darkest?: string;
  lighter?: string;
  lightest?: string;
}

export interface AppPaletteColorOptions extends SimplePaletteColorOptions {
  secondary?: string;
  darker?: string;
  darkest?: string;
  lighter?: string;
  lightest?: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    appGlass?: AppPaletteColor;
    appBlue?: AppPaletteColor;
    appGray?: AppPaletteColor;
  }
  interface PaletteOptions {
    appGlass?: AppPaletteColorOptions;
    appBlue?: AppPaletteColorOptions;
    appGray?: AppPaletteColorOptions;
  }
}

let theme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blue.A400,
    },
    appGlass: {
      main: 'rgba(255, 255, 255, 0.16) !important',
    },
    appBlue: {
      lightest: '#4fc3f7',
      light: '#2979ff',
      main: '#101F33',
      dark: '#081627',
    },
    appGray: {
      darkest: '#101010',
      darker: '#303030',
      dark: '#505050',
      main: '#707070',
      secondary: '#909090',
      light: '#B0B0B0',
      lighter: '#D0D0D0',
      lightest: '#F0F0F0',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'inherit',
    },
    h4: {
      fontSize: 22,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 13,
    },
    button: {
      textTransform: 'none',
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: theme.palette.appGray.dark,
          fontFamily: 'Montserrat, Verdana, Serif',
          wordWrap: 'break-word',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.appBlue.main,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.appBlue.dark,
          borderWidth: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.appGray.darkest,
          color: theme.palette.appBlue.lightest,
          borderBottom: '1px solid',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          overflow: 'scroll',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.appGray.light,
        },
      },
    },
  },
};

export default theme;
