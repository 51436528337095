import React from 'react';
import { Navigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { env, usePoolSignedIn, getPoolTitle } from '../../api';
import PoolDetails from './PoolDetails';
import PoolSignIn from './PoolSignIn';

export default function SinglePoolDashboard({ poolname }: { poolname: string }) {
  const poolSignedIn = usePoolSignedIn(poolname);

  React.useEffect(() => {
    document.title = env.title + ' | ' + getPoolTitle(poolname);
  }, [poolname]);

  if (poolSignedIn) {
    return <PoolDetails poolname={poolname} />;
  } else if (env.multipool) {
    return (
      <Stack alignItems={'center'}>
        <Typography variant={'h4'}>{getPoolTitle(poolname, true)}</Typography>
        <Card sx={{ marginTop: 4, width: 1, minHeight: 30, maxWidth: 400 }}>
          <CardHeader title={'Sign In to the Pool'} sx={{ textAlign: 'center' }} />
          <CardContent>
            <PoolSignIn poolname={poolname} showUsername sx={{ width: 1, maxWidth: 400 }} />
          </CardContent>
        </Card>
      </Stack>
    );
  } else {
    return <Navigate to={'/home'} />;
  }
}
