import React from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  capitalize,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDurationAbbr, SmartLink } from '../../abelcommon';
import { RichList, RichListItem } from '../../components/RichList';
import { env, getPoolAPI } from '../../api';

const ABELSCAN_BLOCK_URL = 'https://explorer.abelian.info/block';

function StyledTable({ children }: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <TableContainer sx={{ width: { xs: 'auto', sm: 1 } }}>
      <Table
        sx={{
          width: { xs: 'max-content', sm: 1 },
          tableLayout: 'auto',
          '& .MuiTableCell-root': { px: 1, py: 1 },
        }}
      >
        {children}
      </Table>
    </TableContainer>
  );
}

export default function PoolDetails({ poolname }: { poolname: string }) {
  const api = getPoolAPI(poolname);
  const userProfileQuery = api.useUserProfile();
  const userMiningQuery = api.useUserMiningInfos();
  const minedBlocksQuery = api.useMinedBlockInfos();
  const isAllSuccess = userProfileQuery.isSuccess && userMiningQuery.isSuccess && minedBlocksQuery.isSuccess;
  const userProfile = isAllSuccess ? userProfileQuery.data.payload : null;
  const userMiningInfos = isAllSuccess ? userMiningQuery.data.payload : null;
  const minedBlocksInfos = isAllSuccess ? minedBlocksQuery.data.payload : null;

  if (!isAllSuccess) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={4}>
      {env.multipool && (
        <Grid item xs={12}>
          <Typography variant={'h4'} sx={{ textAlign: 'center' }}>{`Pool - ${capitalize(poolname)}`}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardHeader title={'Account Profile'} />
          <CardContent>
            <AccountProfile userProfile={userProfile} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={'Latest Account History (up to 100 records)'} />
          <CardContent>
            <AccountMiningHistory userMiningInfos={userMiningInfos} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={'Latest Blocks Mined by the Pool (up to 100 records)'} />
          <CardContent>
            <MinedBlocks minedBlocksInfos={minedBlocksInfos} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function AccountProfile({ userProfile }: { userProfile: any }) {
  const [copyTooltip, setCopyTooltip] = React.useState('Copy to clipboard.');
  const d = userProfile;
  return (
    <RichList>
      <RichListItem title={'Username'} help={'The unique identity of your Abelian Pool account.'}>
        {d.username}
      </RichListItem>
      <RichListItem title={'Created At'} help={'The time when this account was created.'}>
        {new Date(d.createdAt).toString()}
      </RichListItem>
      <RichListItem
        title={'Balance'}
        help={'The total amount of ABEL remaining in the account (allocated but not sent yet).'}
      >
        {(Number(d.balance - d.redeemedBalance) / 1e7).toLocaleString('en-US', { maximumFractionDigits: 18 })} ABEL
      </RichListItem>
      <RichListItem title={'Redeemed'} help={'The total amount of ABEL sent to the account address.'}>
        {(Number(d.redeemedBalance) / 1e7).toLocaleString('en-US', { maximumFractionDigits: 18 })} ABEL
      </RichListItem>
      <RichListItem title={'Total Mined'} help={'The total amount of ABEL mined in this pool (Balance + Redeemed).'}>
        {(Number(d.balance) / 1e7).toLocaleString('en-US', { maximumFractionDigits: 18 })} ABEL
      </RichListItem>
      <RichListItem title={'Address'} help={'The address to receive ABEL earnings.'}>
        {!d.address && 'N/A'}
        {d.address && d.address.length <= 40 && d.address}
        {d.address &&
          d.address.length > 40 &&
          `${d.address.slice(0, 16)}......${d.address.slice(-16)} (length: ${Math.ceil(
            d.address.length / 2
          ).toLocaleString()} bytes)`}
        {d.address && (
          <Tooltip title={copyTooltip} arrow placement={'top'}>
            <Box component={'span'}>
              <Button
                sx={{ mx: 1, minHeight: 0, minWidth: 0, p: 0 }}
                onClick={() => {
                  navigator.clipboard.writeText(d.address);
                  setCopyTooltip('Copied.');
                  setTimeout(() => setCopyTooltip('Copy to clipboard.'), 2000);
                }}
                disabled={copyTooltip === 'Copied.'}
              >
                <ContentCopyIcon sx={{ fontSize: 14 }} />
              </Button>
            </Box>
          </Tooltip>
        )}
      </RichListItem>
    </RichList>
  );
}

function AccountMiningHistory({ userMiningInfos }: { userMiningInfos: any }) {
  const md = userMiningInfos;
  if (md === null) {
    return null;
  }

  const ts = Date.now();
  return (
    <StyledTable>
      <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
        <TableRow>
          <TableCell>Block Range</TableCell>
          <TableCell align={'right'}>Allocation Time</TableCell>
          <TableCell align={'right'}>Allocated ABEL</TableCell>
          <TableCell align={'right'}>Share</TableCell>
          <TableCell align={'right'}>Account / Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ fontFamily: 'Monospace' }}>
        {md.map((d: any, idx: number) => {
          const [startHeight, endHeight] = [Number(d.startHeight), Number(d.endHeight)];
          const [userShareCount, totalShareCount] = [Number(d.userShareCount), Number(d.totalShareCount)];
          const balance = Number(d.balance ?? '0');
          console.log(JSON.stringify(d));
          return (
            <TableRow key={idx}>
              <TableCell>
                <SmartLink to={`${ABELSCAN_BLOCK_URL}/${startHeight}`}>{startHeight}</SmartLink>
                {' - '}
                <SmartLink to={`${ABELSCAN_BLOCK_URL}/${endHeight - 1}`}>{endHeight - 1}</SmartLink>
              </TableCell>
              <TableCell align={'right'}>
                {d.updatedAt !== null && (
                  <Tooltip title={new Date(d.updatedAt).toLocaleString('en-US')} placement={'top-end'}>
                    <Typography>{formatDurationAbbr(new Date(d.updatedAt), ts)} ago</Typography>
                  </Tooltip>
                )}
                {d.updatedAt === null && (
                  <Typography>Not allocated yet</Typography>
                )}
              </TableCell>
              <TableCell align={'right'}>
                {(balance / 1e7).toLocaleString(undefined, { minimumFractionDigits: 7 })}
              </TableCell>
              <TableCell align={'right'}>{`${((userShareCount / totalShareCount) * 100).toFixed(2)}%`}</TableCell>
              <TableCell
                align={'right'}
              >{`${userShareCount.toLocaleString()} / ${totalShareCount.toLocaleString()}`}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}

function MinedBlocks({ minedBlocksInfos }: { minedBlocksInfos: any }) {
  const md = minedBlocksInfos;
  const ts = Date.now();
  return (
    <StyledTable>
      <TableHead sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
        <TableRow>
          <TableCell>Block</TableCell>
          <TableCell align={'right'}>Reward</TableCell>
          <TableCell align={'right'}>Time</TableCell>
          <TableCell>Block Hash</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ fontFamily: 'Monospace' }}>
        {md.map((d: any, idx: number) => (
          <TableRow key={idx}>
            <TableCell>
              <SmartLink to={`${ABELSCAN_BLOCK_URL}/${d.blockHeight}`}>{d.blockHeight}</SmartLink>
            </TableCell>
            <TableCell align={'right'}>
              {(Number(d.reward) / 1e7).toLocaleString('en-US', { maximumFractionDigits: 18 })} ABEL
            </TableCell>
            <TableCell align={'right'}>
              <Tooltip title={new Date(d.blockTimestamp * 1000).toLocaleString('en-US')} placement={'top-end'}>
                <Typography>{formatDurationAbbr(new Date(d.blockTimestamp * 1000), ts)} ago</Typography>
              </Tooltip>
            </TableCell>
            <TableCell sx={{ maxWidth: 180 }}>0x{d.blockHash}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
}
