import React from 'react';
import { Grid, Tooltip, Typography, SxProps, Stack } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export interface RichListItemProps {
  title: string;
  help?: string;
  children?: React.ReactNode;
}

export function RichListItem({ title, help, children }: RichListItemProps) {
  return (
    <Grid container sx={{ py: 1.5 }}>
      <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {help && (
          <Tooltip title={help} arrow placement={'top'}>
            <HelpOutlineIcon sx={{ typography: 'body1', color: 'text.secondary', mr: 0.5 }} />
          </Tooltip>
        )}
        <Typography variant={'body1'} sx={{ fontWeight: { xs: 'bold', sm: 'normal' } }}>
          {title + ':'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        {children}
      </Grid>
    </Grid>
  );
}

export interface RichListProps {
  sx?: SxProps;
  children: React.ReactNode | React.ReactNode[];
}

export function RichList({ sx = {}, children }: RichListProps) {
  return <Stack sx={sx}>{children}</Stack>;
}

export default RichList;
