import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { KeyedObject } from '../utils/types';

function isExternalURL(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (e: unknown) {
    return false;
  }
}

/* eslint-disable-next-line */
export interface SmartLinkProps extends KeyedObject {
  to?: string;
  forceExternal?: boolean;
  target?: string;
}

export const SmartLink = React.forwardRef(
  (
    { to = '', forceExternal = false, target, ...others }: SmartLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    if (!to) {
      return <Link ref={ref} {...others} />;
    }
    const isExternal = forceExternal || isExternalURL(to);
    const realTarget = target ?? (isExternal ? '_blank' : '_self');
    if (isExternal) {
      return (
        <Link
          ref={ref}
          component={'a'}
          {...others}
          href={to}
          target={realTarget}
        />
      );
    } else {
      return (
        <Link
          ref={ref}
          component={RouterLink}
          {...others}
          to={to}
          target={realTarget}
        />
      );
    }
  }
);

export default SmartLink;
