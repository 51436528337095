import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Typography, Box, Divider, Stack, capitalize } from '@mui/material';
import { XBox } from '../components/CenterBox';
import BarDrawerLayout from '../components/BarDrawerLayout';
import { useLayoutState } from '../components/BarDrawerLayout';
import Navigator from './Navigator';
import { env, useUserSignedIn } from '../api';

export function Layout({ requireSignIn = true }: { requireSignIn?: boolean }) {
  return (
    <BarDrawerLayout
      breakpoint={'md'}
      barOptions={{
        desktopHeight: 60,
        mobileHeight: 48,
      }}
      desktopDrawerOptions={{
        mode: 'permanent',
        width: 256,
      }}
      mobileDrawerOptions={{
        width: 256,
      }}
      header={<Header />}
      sideNav={<Navigator />}
      footer={<Divider sx={{ mx: 2, mb: 3 }} />}
      main={<Main requireSignIn={requireSignIn} />}
    />
  );
}

function Header() {
  const [layoutState] = useLayoutState();
  return (
    <Box sx={{ width: 1, height: 1, display: 'flex', alignItems: 'center' }}>
      <Box component={'img'} src={'abel-icon-blue.png'} sx={{ height: 36, width: 36, mx: 1 }} />
      <Stack
        direction={layoutState.isDesktop ? 'row' : 'column'}
        spacing={layoutState.isDesktop ? 1 : 0}
        sx={layoutState.isDesktop ? {} : { width: 1, alignItems: 'center' }}
      >
        <Typography variant={'h4'}>{env.title ?? `${capitalize(env.poolServiceName)}Pool`}</Typography>
        <Typography variant={layoutState.isDesktop ? 'h5' : 'body2'} color={'appGray.lighter'}>
          {`${layoutState.isDesktop ? ' - ' : ''}${env.subtitle}`}
        </Typography>
      </Stack>
    </Box>
  );
}

function Main({ requireSignIn = true }: { requireSignIn?: boolean }) {
  const signedin = useUserSignedIn();
  if (requireSignIn && !signedin) {
    return (
      <XBox sx={{ minHeight: 300 }}>
        <Typography variant={'h6'}>Please sign in to access this page.</Typography>
      </XBox>
    );
  } else {
    return (
      <Container maxWidth={'md'} sx={{ p: { xs: 3, md: 4 } }}>
        <Outlet />
      </Container>
    );
  }
}

export default Layout;
